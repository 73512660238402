<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-data-table
        v-if="listBrands"
        :headers="headers"
        :items="listBrands"
        :loading="isLoadingBrands"
        :server-items-length="brandsMeta.totalItems"
        :options.sync="options"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
          <v-icon small @click="destroy(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("brands") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-row justify="end">
              <v-dialog v-model="isVisibleDialog" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    {{ $t("add_new_brand") }}
                  </v-btn>
                </template>
                <v-form ref="form" v-model="is_valid_form" lazy-validation>
                  <v-card>
                    <v-card-title class="pb-4">
                      <span class="text-h3">{{ $t("brands") }}</span>
                    </v-card-title>
                    <v-card-text class="">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="9">
                            <v-text-field
                              dense
                              outlined
                              :label="$t('name_*')"
                              v-model="editedBrand.name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-select
                              dense
                              outlined
                              :label="$t('status_*')"
                              v-model="editedBrand.status"
                              :items="listStatuses"
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <small>{{ $t("indicates_required_field") }}</small>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="closeForm()">
                        {{ $t("close") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="saveItem(editedBrand)"
                        :disabled="!is_valid_form"
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-dialog v-model="isVisibleDeleteDialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">{{
                    $t("delete_confirmation_message")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeForm">{{
                      $t("cancel")
                    }}</v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="destroyConfirm(editedBrand)"
                      >{{ $t("ok") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_brands = true;
        await this.$store.dispatch("brands/list", this.options).then(() => {
          this.is_loading_brands = false;
          if (this.options.page > this.brandsMeta?.lastPage) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      isLoadingBrands: "brands/isLoadingBrands",
      listBrands: "brands/listBrands",
      brandsMeta: "brands/meta",
      listStatuses: "statuses/listStatuses",
    }),
  },

  data() {
    return {
      options: {},
      is_loading_brands: false,
      is_valid_form: true,
      isVisibleDialog: false,
      isVisibleDeleteDialog: false,
      editedBrand: {},
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
          width: "15%",
        },
      ],
    };
  },
  methods: {
    edit(item) {
      this.isVisibleDialog = true;
      this.editedBrand = Object.assign({}, item);
    },

    async saveItem(item) {
      if (this.$refs.form.validate()) {
        try {
          if (item.id) {
            await this.$store.dispatch("brands/update", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("brands/add", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.$refs.form.reset();
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    destroy(item) {
      this.isVisibleDeleteDialog = true;
      this.editedBrand = Object.assign({}, item);
    },

    async destroyConfirm(item) {
      try {
        await this.$store.dispatch("brands/destroy", item);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.closeForm();
    },

    closeForm() {
      this.isVisibleDialog = false;
      this.isVisibleDeleteDialog = false;
      this.editedBrand = {};
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
